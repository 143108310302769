import React from "react";
import mergeObject from "@gqlapp/base/utils/merge/mergeObject";
import redirectTo from '@xem/common/redirect';
import { getRoute, ROUTER } from "@xem/router-common";
import clientModules from '@xem/client';
import User from "@xem/user-common/classes/User";
import { getItem } from "@gqlapp/core-common/clientStorage";

/**
 * Default Page
 * @param props
 * @constructor
 */
export default function Page(props: any){
  // @ts-ignore
  return React.createElement(clientModules.router, props)
}

/**
 * getInitialProps
 * @param load
 * @param route_type
 * @param apolloClient
 * @param asPath
 * @param path
 * @param headers
 * @param query
 * @param ctx
 */
export const getInitialProps = async (
  { load, route_type, apolloClient, asPath, path, headers, query, ...ctx }: any  & { route_type: string } = {}
)=>{
  let start_time = (new Date()).getTime(), runtimejs = false, type_name: string, graphqlRoute: any = {}, session: any = {}, localKey: string;
	let	currentUser: any, redirect: string, site: string, firebaseSettings: any;
  let history: any = [], pathLast: any, cookiePaths: any;
  if(isTrue('DEBUG')) {
    error('getInitialProps', start_time)
  }
  path = path || '/';

  let pathto = path.replace('/dashboard', '/');

  ctx = mergeObject({ headers, apolloClient, path, asPath, query }, ctx );

  try{
    if(!__SERVER__){
      cookiePaths = getCookie('history', ctx)
      if(cookiePaths){ history = JSON.parse(cookiePaths); }
      history = history || [];
    }

    try{ pathLast = [...history].at(-1) }catch (e) {}

    if(pathLast !== pathto){
      history.push(pathto);
    }

    history = [...history.slice(-5)]

    setCookie('history', JSON.stringify(history), __SERVER__ ? { req: ctx?.req, res: ctx?.res }: null);
    Object.assign(ctx, { history });

  }catch (e) {
    debug('##### error ####', e)
  }


  try {
    let vars = (parseInt(query?.qrtag) === 1)? 'qrcode': 'view';

    // debug('######withRouteContainer',{ path, vars, apolloClient })
    /** Load du lieu website cung voi cookie */
    const result = await getRoute({ variables: { path, vars }})(apolloClient);

    // debug('######withRouteContainer',{ result, route_type })
    let data: any = result?.data || {};

    await apolloClient.writeQuery({ query: ROUTER, data, variables: { path }});

    /** Set variable */
    currentUser = data?.currentUser || {};
    site = data?.site || {};
    session = data?.session || {};
    localKey = data?.localkey;
    firebaseSettings = data?.site?.firebase || {}
    /*** The variable has been set */

    if(data?.res?.runtimejs) runtimejs = true;

    /** * Checked load database */
    if(load){
      graphqlRoute = data?.res || {};
      type_name = graphqlRoute?.type_name;
      if(type_name === 'TypeIsRedirect' && graphqlRoute?.title){
        redirect = graphqlRoute?.title;
      }
    }
  }catch (e) {
    debug(path, e)
  }
  
  // debug('######graphqlRoute', load, graphqlRoute)
  route_type = route_type || type_name;

  /*** redirect from getInitialProps */
  if(type_name === 'TypeIsRedirect' && redirect && redirect !== path){
    redirectTo(redirect, ctx);
  }

  /** get route_type **/
  clientModules.props = { route_type, path };
  (clientModules.typeByRoute) && (route_type = clientModules.typeByRoute);

  // debug('######withRoute', 'getInitialProps', { route_type, type_name, query })

  try{ const preUrl = await getItem('location'); ctx = mergeObject({ preUrl }, ctx); }catch (e) { debug(e); }
  ctx = mergeObject({ localKey, session, site, firebaseSettings, currentUser, headers, graphqlRoute, route_type }, ctx)
  try{
    let routeProps: any = clientModules.getRouteProps;

    if(routeProps){
      const { perms, redirectOnLoggedIn, redirectOnAnonymous, redirect: to = '/' } = routeProps || {};
      if(routeProps?.runtimejs) runtimejs = true;

      ctx = mergeObject({ runtimejs, start_time }, ctx);

      /**
       * check type of page
       */
      try{
        let user = User.instanse(currentUser);

        if(to !== path && (
            (typeof redirectOnLoggedIn == 'boolean' && redirectOnLoggedIn && !!user.id) ||
            (typeof redirectOnAnonymous == 'boolean' && redirectOnAnonymous && !user.id) ||
            ((perms && !user.hasPerms(perms)))
        )){
          redirectTo(to, ctx);
        }
      }catch (e) {
        console.error(e)
      }

      /*** */
      (routeProps.getInitialProps) && (ctx = mergeObject(await routeProps.getInitialProps(ctx), ctx));
    }

  }catch (e) {
    console.error(e)
  }

  return ctx;
}
